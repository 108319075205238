import clsx from "clsx";
import { forwardRef, useState } from "react";
import { CustomDateInputField } from "./CustomDateInputField";
import Util from "../../utils/Util";

const SingleInputField = forwardRef(
  (
    {
      leadingComponent,
      tailingComponent,
      id,
      type,
      value,
      label,
      onChange,
      required,
      readOnly: disabled,
      options,
      showLabel = false
    },
    ref
  ) => {
    const [isFocus, setIsFocus] = useState(false);
    return (
      <div
        className={`${"w-full"} h-[3.5rem] rounded-xl flex items-center justify-center ${
          isFocus ? "bg-[#E5F1FF]" : "bg-[#979797]/[0.1]"
        }`}>
        {leadingComponent && (
          <div
            className={`w-1/6 flex items-center justify-center ${
              isFocus ? "opacity-100" : "opacity-50"
            }`}>
            {leadingComponent}
          </div>
        )}
        {showLabel && (
          <label
            htmlFor={id}
            className={`hidden shrink-0 md:block mr-2 ml-7 ${
              isFocus ? "opacity-100" : "opacity-50"
            }`}>
            {label}
            {required && <span className="text-red-500"> * </span>}:
          </label>
        )}
        {type === "select" ? (
          <select
            disabled={disabled}
            className={clsx(
              "w-full h-[3.59744rem] focus-within:opacity-100 font-[Manrope] font-bold text-base leading-[0.875rem] mr-2 tracking-[-0.03rem] text-[#2B3674] placeholder:text-[#2B3674] rounded-xl focus:outline-none bg-transparent",
              isFocus ? "opacity-100" : "opacity-50"
            )}
            onChange={(event) => onChange(event.target.value)}
            value={value}>
            {options.map((option, index) => (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        ) : type === "date" ? (
          <CustomDateInputField
            id={id}
            name={id}
            value={value}
            required={required}
            disabled={disabled}
            onChange={onChange}
          />
        ) : (
          <input
            className={clsx(
              "grow h-[3.59744rem] font-[Manrope] pr-2 font-bold text-base leading-[0.875rem] tracking-[-0.03rem] text-[#2B3674] placeholder:text-[#2B3674] rounded-xl focus:outline-none bg-transparent",
              isFocus ? "opacity-100" : "opacity-50",
              leadingComponent ? "" : showLabel ? "" : "ml-7"
            )}
            type={type}
            defaultValue={value}
            id={id}
            ref={ref}
            name={id}
            placeholder={!showLabel ? label : ""}
            onChange={(event) => {
              if (type === "date") {
                if (
                  Util.compareDates(event.target.value, new Date().toISOString().split("T")[0]) > 0
                ) {
                  event.target.value = new Date().toISOString().split("T")[0];
                }
              }

              onChange(event.target.value);
            }}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            required={required}
            disabled={disabled}
            max={
              type === "date"
                ? new Date().toISOString().split("T")[0]
                : type === "number"
                  ? 999999
                  : ""
            }
          />
        )}
        {tailingComponent && (
          <div
            className={`w-1/6 flex items-center justify-center cursor-pointer ${
              isFocus ? "opacity-100 text-[#41579B]" : "opacity-50"
            }`}>
            {tailingComponent}
          </div>
        )}
      </div>
    );
  }
);

SingleInputField.displayName = "SingleInputField";

export default SingleInputField;

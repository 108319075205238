import React, { useEffect, useState } from "react";
import { signal } from "@preact/signals-react";
import { Transition, Menu } from "@headlessui/react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Util from "../utils/Util";
import CookieService from "../services/CookieService";
import CustomPagination from "../components/CustomPagination";
import CourseService from "../services/CourseService";

const pageSignal = signal(1);
const coursesSignal = signal({
  result: [],
  totalPages: 0
});
const searchCriteriaListSignal = signal([
  {
    filterKey: "title",
    operation: "cn",
    value: ""
  }
]);
const openingCourseSignal = signal(null);
const modalOpen = signal(false);
const deletingCourseIdsSignal = signal([]);
const selectAllSignal = signal(false);

async function searchCourses({ page }) {
  let courses = {
    result: [],
    totalPages: 0
  };
  try {
    const userId = Util.getClaim(CookieService.getCookie("token"), "id");
    if (userId) {
      const searchCriteriaList = [
        {
          filterKey: "userId",
          operation: "eq",
          value: userId
        },
        ...searchCriteriaListSignal.value
      ];

      const res = await CourseService.searchCourses({
        page: page,
        size: 12,
        sortBy: "createdAt",
        order: 0,
        dataOption: "all",
        searchCriteriaList: searchCriteriaList
      });
      courses = res.data;
    }
  } catch (error) {
    console.error(error);
  }

  return courses;
}

function EditorCourseListPage() {
  const [listViewMode, setListViewMode] = useState(false);

  useEffect(() => {
    searchCourses({
      page: pageSignal.value
    }).then((res) => {
      coursesSignal.value = res;
    });

    selectAllSignal.value = false;
    deletingCourseIdsSignal.value = [];
  }, [pageSignal.value]);

  useEffect(() => {
    pageSignal.value = 1;
  }, []);

  return (
    <div className="py-12 px-16 bg-[#F4F7FE] min-h-[calc(100vh-6rem)] w-full grow">
      <FormDialog />
      <h1 className="text-6xl font-bold mb-8">Courses</h1>
      <div className="w-full h-max flex items-center justify-between mb-10">
        <button
          className="w-max h-max bg-[#2639ED] rounded-md text-white text-sm font-medium leading-3 flex items-center justify-start px-5 gap-4 py-3 disabled:bg-gray-300"
          // disabled={coursesSignal.value.result.length > 0}
          onClick={() => {
            openingCourseSignal.value = null;
            modalOpen.value = true;
          }}>
          <img src="/assets/plus.svg" alt="plus" />
          <p>New Course</p>
        </button>
        <div className="flex items-center justify-end gap-6">
          <SearchBar />
          <img
            style={{
              filter: listViewMode
                ? "brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(1968%) hue-rotate(214deg) brightness(86%) contrast(96%)"
                : "none"
            }}
            className="w-5 h-5 cursor-pointer"
            src="/assets/list-view.svg"
            alt="list view"
            onClick={() => {
              setListViewMode(true);
            }}
          />
          <img
            style={{
              filter: !listViewMode
                ? "brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(1968%) hue-rotate(214deg) brightness(86%) contrast(96%)"
                : "none"
            }}
            className="w-5 h-5 cursor-pointer"
            src="/assets/grid-view.svg"
            alt="grid view"
            onClick={() => {
              setListViewMode(false);
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-5">
        <div className="w-56 h-20">
          <FilterButton
            icon={{ src: "/assets/filter-clock.svg", alt: "list all" }}
            text="All"
            onClick={() => {}}
          />
        </div>
        <div className="w-56 h-20">
          <FilterButton
            icon={{ src: "/assets/filter-favorite.svg", alt: "favorite" }}
            text="Favorite"
            onClick={() => {}}
          />
        </div>
      </div>
      <CourseList
        data={coursesSignal.value.result}
        pagingSignal={pageSignal}
        totalPages={coursesSignal.value.totalPages}
        listViewMode={listViewMode}
      />
    </div>
  );
}

function FilterButton({ text, icon, onClick }) {
  return (
    <button
      className="w-full h-full flex items-center justify-start gap-4 px-4 py-5 pr-7 bg-white rounded-[1.25rem]"
      onClick={onClick}>
      <img src={icon.src} alt={icon.alt} />
      <p className="text-[#2B3674] font-bold text-xl">{text}</p>
    </button>
  );
}

function CourseList({ data, pagingSignal, totalPages, listViewMode }) {
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      selectAllSignal.value = true;
      deletingCourseIdsSignal.value = data.map((item) => item.id);
    } else {
      selectAllSignal.value = false;
      deletingCourseIdsSignal.value = [];
    }
  };

  const handleDelete = async () => {
    try {
      await CourseService.deleteCourses({ ids: deletingCourseIdsSignal.value });
      const res = await searchCourses({ page: pageSignal.value });
      coursesSignal.value = res;
      selectAllSignal.value = false;
      deletingCourseIdsSignal.value = [];
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {data.length < 1 ? (
        <div className="w-full h-[35rem] flex flex-col items-center justify-start">
          <img src="/assets/empty.png" alt="empty" />
          <div className="-mt-24 text-center">
            <p className="text-[#4A4A4A] text-xl font-bold">There is no course</p>
            <p className="text-[#4B4B4B] text-xl font-normal leading-6">
              Let&apos;s create a new one!
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-[3.44rem]">
          <div className="flex gap-3 flex-wrap mb-16">
            {data.map((course, index) =>
              listViewMode ? (
                <CourseListRow key={index} index={index} course={course} />
              ) : (
                <CourseCard key={index} course={course} />
              )
            )}
          </div>
          <div className={`flex items-center justify-start ${listViewMode ? "w-[80%]" : "w-full"}`}>
            {listViewMode && (
              <div className="flex items-center pl-6">
                <Checkbox onChange={handleSelectAll} />
                <p>Select all</p>
                <button
                  className="flex items-center ml-8 text-[#004EC3] text-sm gap-2 pt-[0.56rem] pb-[0.62rem] px-4 bg-[#C6E6FF] rounded-full"
                  onClick={handleDelete}>
                  <i className="mb-0.5">
                    <img src="/assets/recycle-bin.svg" alt="recycle bin" />
                  </i>
                  <p>Delete</p>
                </button>
              </div>
            )}
            <div
              className={`flex grow items-center ${
                listViewMode ? "justify-end" : "justify-center"
              }`}>
              <CustomPagination
                count={totalPages}
                setCurrentPage={(page) => {
                  pagingSignal.value = page;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function SearchBar() {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();
    searchCriteriaListSignal.value = searchCriteriaListSignal.value.map((item) => {
      if (item.filterKey === "title") {
        return {
          ...item,
          value: searchText
        };
      }
      return item;
    });
    searchCourses({ page: pageSignal.value }).then((res) => {
      coursesSignal.value = res;
    });
  };

  return (
    <form className="px-8 py-4 bg-white rounded-full flex items-center justify-start gap-5">
      <button onClick={handleSearch}>
        <img
          className="w-[0.8125rem] h-[0.8125rem]"
          src="/assets/magnifying-glass.svg"
          alt="search"
        />
      </button>
      <input
        className="placeholder:text-[#8F9BBA] text-base font-normal outline-none"
        type="text"
        placeholder="Search"
        value={searchText}
        onChange={(e) => {
          setSearchText(e.target.value);
        }}
      />
    </form>
  );
}

function CourseCard({ course }) {
  const navigate = useNavigate();

  const openEditModal = () => {
    modalOpen.value = true;
    openingCourseSignal.value = course;
  };

  const handleDelete = async () => {
    try {
      await CourseService.deleteCourse({ id: course.id });
      const res = await searchCourses({ page: pageSignal.value });
      coursesSignal.value = res;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-[19rem] h-[21rem] relative flex flex-col">
      <img
        className="h-[9.375rem] w-full object-cover rounded-t"
        src="/assets/exam-thumbnail.jpg"
        alt="thumbnail"
      />
      <div className="px-3 pt-2 bg-white pb-[1.55rem] grow flex flex-col rounded-b">
        <div className="flex items-center justify-between">
          <h6
            className="text-[#4B4B4B] text-base font-normal hover:underline cursor-pointer"
            onClick={() => {
              navigate(`/editor/course?id=${course.id}`);
            }}>
            {course.title}
          </h6>
        </div>
        <p className="mt-2 text-sm">{course.description}</p>
        <Menu as="div" className="absolute right-3 bottom-3 text-left z-10">
          <div>
            <Menu.Button className="inline-flex justify-center w-6 h-6 items-center">
              <i>
                <img src="/assets/three-dots.svg" alt="three dot" />
              </i>
            </Menu.Button>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={openEditModal}>
                      Edit
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={handleDelete}>
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

function CourseListRow({ course, index }) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectAllSignal.value) {
      setChecked(true);
    } else {
      setChecked(deletingCourseIdsSignal.value.includes(course.id));
    }
  }, [selectAllSignal.value]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setChecked(true);
      deletingCourseIdsSignal.value = [...deletingCourseIdsSignal.value, course.id];
    } else {
      setChecked(false);
      deletingCourseIdsSignal.value = deletingCourseIdsSignal.value.filter(
        (id) => id !== course.id
      );
    }
  };

  return (
    <div
      className={`flex items-center w-[80%] h-14 text-[#4B4B4B] text-sm font-normal pl-6 ${
        index % 2 === 0 ? "" : "bg-white"
      }`}>
      <div className="w-[5%]">
        <Checkbox onChange={handleCheck} checked={checked} />
      </div>
      <p
        className="w-1/5 hover:underline cursor-pointer"
        onClick={() => {
          navigate(`/editor/course?id=${course.id}`);
        }}>
        {course.title}
      </p>
    </div>
  );
}

function FormDialog() {
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (openingCourseSignal.value) {
      setTitle(openingCourseSignal.value.title);
      setDescription(openingCourseSignal.value.description);
      setId(openingCourseSignal.value.id);
    }
  }, [openingCourseSignal.value]);

  const handleClose = () => {
    modalOpen.value = false;
    clearForm();
  };

  const handleSave = async () => {
    const requiredFields = [title];

    if (requiredFields.some((field) => !field)) {
      setError("Please fill in all required fields");
      return;
    }

    setIsSaving(true);

    try {
      if (id) {
        await CourseService.updateCourse({
          id,
          title,
          description
        });
      } else {
        await CourseService.createCourse({ title, description });
      }
      const res = await searchCourses({ page: pageSignal.value });
      coursesSignal.value = res;
    } catch (error) {
      console.error(error);
    }

    setIsSaving(false);
    clearForm();
    modalOpen.value = false;
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setId("");
    setError("");
  };

  const handleTitleChange = (e) => {
    setError("");
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setError("");
    setDescription(e.target.value);
  };

  return (
    <React.Fragment>
      <Dialog open={modalOpen.value} onClose={handleClose}>
        <DialogTitle>{openingCourseSignal.value ? "Edit Course" : "New Course"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            sx={{
              color: "red"
            }}>
            {error}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            required
            onChange={handleTitleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            multiline
            value={description}
            onChange={handleDescriptionChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>{isSaving ? <CircularProgress /> : "Save"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditorCourseListPage;

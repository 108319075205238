import { Popover, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import CookieService from "../services/CookieService";
import { userInfoSignal } from "../signals/UserInfoSignal";
import Util from "../utils/Util";
import Avatar from "./avatar";
import { signedInState } from "../signals";

export function CustomAvatar() {
  const navigate = useNavigate();

  const signOut = () => {
    CookieService.removeCookie("token");
    signedInState.value = {
      ...signedInState.value,
      isSignedIn: false
    };
    navigate("/login");
  };

  return (
    <div className="w-[2.5625rem] h-[2.5625rem] rounded-full">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`
          ${open ? "text-white" : "text-white/90"}
          group inline-flex items-center w-[2.5625rem] h-[2.5625rem] rounded-full text-base font-medium hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}>
              <Avatar userInfo={userInfoSignal.value} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute bg-white rounded-lg -left-full z-20 mt-3 min-w-[10rem] max-w-sm -translate-x-1/2 transform sm:px-0 lg:max-w-3xl">
                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black/5 p-2">
                  <div className="w-full rounded-xl p-2 hover:bg-[#F4F7FE] text-[#8695c2] hover:text-[#2B3674] text-base font-medium">
                    <Link
                      to={"/profile"}
                      className="min-w-[9rem] h-[2rem] flex items-center justify-start gap-2">
                      <img width={24} src="/assets/profile-icon.svg" alt="icon" />
                      <span>Trang cá nhân</span>
                    </Link>
                  </div>
                  {Util.hasAuthority(CookieService.getCookie("token"), "ADMIN") && (
                    <>
                      <div className="rounded-xl p-2 hover:bg-[#F4F7FE] text-[#8695c2] hover:text-[#2B3674] text-base font-medium">
                        <button
                          onClick={() => navigate("/admin")}
                          className="md:w-max md:h-[2rem] flex items-center justify-start gap-2">
                          <img src="/assets/admin-ico.png" alt="admin icon" width={24} />
                          <span>Admin</span>
                        </button>
                      </div>
                    </>
                  )}
                  {Util.hasAuthority(CookieService.getCookie("token"), "EDITOR") && (
                    <>
                      <div className="rounded-xl p-2 hover:bg-[#F4F7FE] text-[#8695c2] hover:text-[#2B3674] text-base font-medium">
                        <button
                          onClick={() => navigate("/editor/courses")}
                          className="md:w-max md:h-[2rem] flex items-center justify-start gap-2">
                          <img width={24} src="/assets/writer.png" alt="editor icon" />
                          <span className="text-left">Quản lý khóa học</span>
                        </button>
                      </div>
                    </>
                  )}
                  <div className="rounded-xl p-2 hover:bg-[#F4F7FE] text-[#8695c2] hover:text-[#2B3674] text-base font-medium">
                    <button
                      onClick={signOut}
                      className="md:w-max md:h-[2rem] flex items-center justify-start gap-2">
                      <img src="/assets/logout-icon.svg" alt="icon" />
                      <span>Đăng xuất</span>
                    </button>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}

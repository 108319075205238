import Cookies from "js-cookie";

class CookieService {
  static setCookie(name, value, days) {
    Cookies.set(name, value, { expires: days, secure: process.env.NODE_ENV === "production" });
  }

  static getCookie(name) {
    return Cookies.get(name);
  }

  static removeCookie(name) {
    Cookies.remove(name);
  }
}

export default CookieService;

import { Typography } from "@mui/material";
import { Fragment, useRef, useState } from "react";
import UserService from "../../services/UserService";
import SingleInputField from "../common/SingleInputField";

const errorFields = {
  "current-password": "Current password is",
  "new-password":
    "Password must contain, different from the current password, New password is required",
  "confirm-password": "confirm password"
};

function ChangePassword({ setStatus, setDetailStatus, openAlert, handleClose }) {
  const [currentPassword, setCurrentPassword] = useState({
    value: "",
    visible: false
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    visible: false
  });
  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    visible: false
  });
  const [responseError, setResponseError] = useState("");
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const passwordFields = [
    {
      id: "current-password",
      ref: currentPasswordRef,
      tailingComponent: (
        <img
          src={`${
            currentPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="password visibility icon"
          onClick={() => {
            setCurrentPassword({
              ...currentPassword,
              visible: !currentPassword.visible
            });
          }}
        />
      ),
      type: currentPassword.visible ? "text" : "password",
      value: currentPassword.value,
      label: "Mật khẩu hiện tại",
      onChange: (value) => setCurrentPassword({ ...currentPassword, value: value })
    },
    {
      id: "new-password",
      ref: newPasswordRef,
      tailingComponent: (
        <img
          src={`${
            newPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="new password visibility icon"
          onClick={() => {
            setNewPassword({ ...newPassword, visible: !newPassword.visible });
          }}
        />
      ),
      type: newPassword.visible ? "text" : "password",
      value: newPassword.value,
      label: "Mật khẩu mới",
      onChange: (value) => setNewPassword({ ...newPassword, value: value })
    },
    {
      id: "confirm-password",
      ref: confirmPasswordRef,
      tailingComponent: (
        <img
          src={`${
            confirmPassword.visible
              ? "../../../assets/visibility-off-icon.svg"
              : "../../../assets/visibility-icon.svg"
          }`}
          alt="confirm password visibility icon"
          onClick={() => {
            setConfirmPassword({
              ...confirmPassword,
              visible: !confirmPassword.visible
            });
          }}
        />
      ),
      type: confirmPassword.visible ? "text" : "password",
      value: confirmPassword.value,
      label: "Nhập lại mật khẩu mới",
      onChange: (value) => setConfirmPassword({ ...confirmPassword, value: value })
    }
  ];

  const handleClearInput = () => {
    currentPasswordRef.current.value = "";
    newPasswordRef.current.value = "";
    confirmPasswordRef.current.value = "";
  };

  const changePassword = async () => {
    try {
      handleClose(null, "Submit");
      setResponseError("");
      await UserService.changePassword({
        currentPassword: currentPassword.value,
        newPassword: newPassword.value,
        confirmPassword: confirmPassword.value
      });
      setStatus("success");
      setDetailStatus("Cập nhật mật khẩu thành công!");
      openAlert();
      handleClearInput();
    } catch (error) {
      setResponseError(error.response.data);
    }
  };

  const getErrorMsg = (error, fieldName) => {
    const { message } = error;
    if (!message) {
      return;
    }
    const fields = fieldName?.split(",")?.map((x) => x.trim().toLowerCase()) || [];
    return [
      ...new Set(
        fields
          .filter((field) => message.toLowerCase().includes(field))
          .map((field) => {
            switch (field) {
              case "current password is":
                if (message.includes("required")) {
                  return "Yêu cầu nhập mật khẩu hiện tại";
                } else return "Mật khẩu hiện tại không đúng";
              case "new password is required":
                return "Yêu cầu nhập mật khẩu mới";
              case "password must contain":
                return "Mật khẩu phải chứa ít nhất 8 ký tự, bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt";
              case "different from the current password":
                return "Mật khẩu mới phải khác với mật khẩu hiện tại";
              case "confirm password":
                if (message.includes("required")) {
                  return "Yêu cầu nhập mật khẩu xác nhận";
                }
                return "Mật khẩu xác nhận không khớp";
              default:
                return "";
            }
          })
      )
    ].join(", ");
  };

  return (
    <div className="w-full md:w-[65%] flex justify-center bg-white rounded-xl md:bg-none mt-12 md:mt-0 p-[10%] md:p-0">
      <div className="flex flex-col justify-center w-full md:ml-[20%]">
        <div className="w-full flex flex-col items-center gap-6">
          <h2 className="text-[#2B3674] text-[1.75rem] font-bold leading-normal">
            Thay đổi mật khẩu
          </h2>
          {passwordFields.map((field, index) => (
            <Fragment key={index}>
              <SingleInputField
                id={field.id}
                ref={field.ref}
                inputKey={field.inputKey}
                tailingComponent={field.tailingComponent}
                type={field.type}
                value={field.value}
                label={field.label}
                onChange={field.onChange}
              />
              {responseError && getErrorMsg(responseError, errorFields[field.id]) ? (
                <Typography
                  color="error"
                  sx={{
                    alignSelf: "start",
                    fontSize: "small",
                    fontStyle: "italic",
                    paddingLeft: "0.25rem",
                    marginTop: "-10px"
                  }}>
                  {getErrorMsg(responseError, errorFields[field.id])}
                </Typography>
              ) : null}
            </Fragment>
          ))}
          <button
            className="w-full py-6 md:p-0 md:w-[20.375rem] md:h-[3.75rem] bg-[#3D10EA] rounded-xl md:rounded-lg text-white text-base font-[Inter] font-semibold leading-4 tracking-[-0.03rem]"
            style={{
              background:
                "-webkit-linear-gradient(122deg, rgb(33, 155, 204) -20%,rgba(14, 9, 129, 0.75) 100%)"
            }}
            onClick={changePassword}>
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;

import React, { useEffect, useState } from "react";
import { signal } from "@preact/signals-react";
import { Transition, Menu } from "@headlessui/react";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from "@mui/material";

import { useNavigate, useSearchParams } from "react-router-dom";
import CustomPagination from "../components/CustomPagination";
import ChapterService from "../services/ChapterService";
import CourseService from "../services/CourseService";

const pageSignal = signal(1);
const courseSignal = signal({});
const chaptersSignal = signal({
  result: [],
  totalPages: 0
});
const openingChapterSignal = signal(null);
const modalOpen = signal(false);
const deletingIdsSignal = signal([]);
const selectAllSignal = signal(false);

async function getChapters({ courseId, page }) {
  try {
    const res = await CourseService.getChapters({
      courseId,
      page,
      size: 12,
      sortBy: "order",
      order: "asc"
    });
    return res.data;
  } catch (error) {
    console.error(error);
  }
}

function EditorChapterListPage() {
  let [searchParams] = useSearchParams();
  const [listViewMode, setListViewMode] = useState(false);

  useEffect(() => {
    if (courseSignal.value.id) {
      getChapters({
        courseId: courseSignal.value.id,
        page: pageSignal.value
      }).then((res) => {
        chaptersSignal.value = res;
      });
    }
  }, [pageSignal.value, courseSignal.value.id]);

  useEffect(() => {
    pageSignal.value = 1;
    selectAllSignal.value = false;
    deletingIdsSignal.value = [];

    CourseService.getCourse({ id: searchParams.get("id") })
      .then((res) => {
        courseSignal.value = res.data;
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <div className="py-12 px-16 bg-[#F4F7FE] min-h-[calc(100vh-6rem)] w-full grow">
      <FormDialog />
      <h1 className="text-6xl font-bold mb-8">Chapters of {courseSignal.value.title}</h1>
      <div className="w-full h-max flex items-center justify-between mb-10">
        <button
          onClick={() => {
            openingChapterSignal.value = null;
            modalOpen.value = true;
          }}
          className="w-max h-max bg-[#2639ED] rounded-md text-white text-sm font-medium leading-3 flex items-center justify-start px-5 gap-4 py-3">
          <img src="/assets/plus.svg" alt="plus" />
          <p>New Chapter</p>
        </button>
        <div className="flex items-center justify-end gap-6">
          <img
            style={{
              filter: listViewMode
                ? "brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(1968%) hue-rotate(214deg) brightness(86%) contrast(96%)"
                : "none"
            }}
            className="w-5 h-5 cursor-pointer"
            src="/assets/list-view.svg"
            alt="list view"
            onClick={() => {
              setListViewMode(true);
            }}
          />
          <img
            style={{
              filter: !listViewMode
                ? "brightness(0) saturate(100%) invert(18%) sepia(88%) saturate(1968%) hue-rotate(214deg) brightness(86%) contrast(96%)"
                : "none"
            }}
            className="w-5 h-5 cursor-pointer"
            src="/assets/grid-view.svg"
            alt="grid view"
            onClick={() => {
              setListViewMode(false);
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-5">
        <div className="w-56 h-20">
          <FilterButton
            icon={{ src: "/assets/filter-clock.svg", alt: "list all" }}
            text="All"
            onClick={() => {}}
          />
        </div>
        <div className="w-56 h-20">
          <FilterButton
            icon={{ src: "/assets/filter-favorite.svg", alt: "favorite" }}
            text="Favorite"
            onClick={() => {}}
          />
        </div>
      </div>
      <ChapterList
        data={chaptersSignal.value.result}
        pagingSignal={pageSignal}
        totalPages={chaptersSignal.value.totalPages}
        listViewMode={listViewMode}
      />
    </div>
  );
}

function FilterButton({ text, icon, onClick }) {
  return (
    <button
      className="w-full h-full flex items-center justify-start gap-4 px-4 py-5 pr-7 bg-white rounded-[1.25rem]"
      onClick={onClick}>
      <img src={icon.src} alt={icon.alt} />
      <p className="text-[#2B3674] font-bold text-xl">{text}</p>
    </button>
  );
}

function ChapterList({ data, pagingSignal, totalPages, listViewMode }) {
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      selectAllSignal.value = true;
      deletingIdsSignal.value = data.map((item) => item.id);
    } else {
      selectAllSignal.value = false;
      deletingIdsSignal.value = [];
    }
  };

  const handleDelete = async () => {
    try {
      await ChapterService.deleteChapters({ ids: deletingIdsSignal.value });
      const res = await getChapters({
        courseId: courseSignal.value.id,
        page: pageSignal.value
      });
      chaptersSignal.value = res;
      selectAllSignal.value = false;
      deletingIdsSignal.value = [];
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {data.length < 1 ? (
        <div className="w-full h-[35rem] flex flex-col items-center justify-start">
          <img src="/assets/empty.png" alt="empty" />
          <div className="-mt-24 text-center">
            <p className="text-[#4A4A4A] text-xl font-bold">There is no chapter</p>
            <p className="text-[#4B4B4B] text-xl font-normal leading-6">
              Let&apos;s create a new one!
            </p>
          </div>
        </div>
      ) : (
        <div className="flex flex-col mt-[3.44rem]">
          <div className="flex gap-3 flex-wrap mb-16">
            {data.map((chapter, index) =>
              listViewMode ? (
                <ChapterListRow key={index} index={index} chapter={chapter} />
              ) : (
                <ChapterCard key={index} chapter={chapter} />
              )
            )}
          </div>
          <div className={`flex items-center justify-start ${listViewMode ? "w-[80%]" : "w-full"}`}>
            {listViewMode && (
              <div className="flex items-center pl-6">
                <Checkbox onChange={handleSelectAll} />
                <p>Select all</p>
                <button
                  className="flex items-center ml-8 text-[#004EC3] text-sm gap-2 pt-[0.56rem] pb-[0.62rem] px-4 bg-[#C6E6FF] rounded-full"
                  onClick={handleDelete}>
                  <i className="mb-0.5">
                    <img src="/assets/recycle-bin.svg" alt="recycle bin" />
                  </i>
                  <p>Delete</p>
                </button>
              </div>
            )}
            <div
              className={`flex grow items-center ${
                listViewMode ? "justify-end" : "justify-center"
              }`}>
              <CustomPagination
                count={totalPages}
                setCurrentPage={(page) => {
                  pagingSignal.value = page;
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function ChapterCard({ chapter }) {
  const navigate = useNavigate();

  const openEditModal = () => {
    modalOpen.value = true;
    openingChapterSignal.value = chapter;
  };

  const handleDelete = async () => {
    try {
      await ChapterService.deleteChapter({ id: chapter.id });
      const res = await getChapters({
        courseId: courseSignal.value.id,
        page: pageSignal.value
      });
      chaptersSignal.value = res;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="w-[19rem] h-[21rem] relative flex flex-col">
      <img
        className="h-[9.375rem] w-full object-cover rounded-t"
        src="/assets/exam-thumbnail.jpg"
        alt="thumbnail"
      />
      <div className="px-3 pt-2 bg-white pb-[1.55rem] grow flex flex-col rounded-b">
        <div className="flex items-center justify-between">
          <h6
            className="text-[#4B4B4B] text-base font-normal hover:underline cursor-pointer w-full line-clamp-2"
            onClick={() => {
              navigate(`/editor/chapter?id=${chapter.id}`);
            }}>
            {chapter.title}
          </h6>
        </div>
        <p className="mt-2 text-sm">{chapter.description}</p>
        <Menu as="div" className="absolute right-3 bottom-3 text-left z-10">
          <div>
            <Menu.Button className="inline-flex justify-center w-6 h-6 items-center">
              <i>
                <img src="/assets/three-dots.svg" alt="three dot" />
              </i>
            </Menu.Button>
          </div>
          <Transition
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
              <div className="px-1 py-1 ">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={openEditModal}>
                      Edit
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${
                        active ? "bg-violet-500 text-white" : "text-gray-900"
                      } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                      onClick={handleDelete}>
                      Delete
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

function ChapterListRow({ chapter, index }) {
  const navigate = useNavigate();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (selectAllSignal.value) {
      setChecked(true);
    } else {
      setChecked(deletingIdsSignal.value.includes(chapter.id));
    }
  }, [selectAllSignal.value]);

  const handleCheck = (e) => {
    if (e.target.checked) {
      setChecked(true);
      deletingIdsSignal.value = [...deletingIdsSignal.value, chapter.id];
    } else {
      setChecked(false);
      deletingIdsSignal.value = deletingIdsSignal.value.filter((id) => id !== chapter.id);
    }
  };

  return (
    <div
      className={`flex items-center w-[80%] h-14 text-[#4B4B4B] text-sm font-normal pl-6 ${
        index % 2 === 0 ? "" : "bg-white"
      }`}>
      <div className="w-[5%]">
        <Checkbox onChange={handleCheck} checked={checked} />
      </div>
      <p
        className="w-1/5 hover:underline cursor-pointer"
        onClick={() => {
          navigate(`/editor/chapter?id=${chapter.id}`);
        }}>
        {chapter.title}
      </p>
    </div>
  );
}

function FormDialog() {
  const [isSaving, setIsSaving] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [id, setId] = useState("");
  const [order, setOrder] = useState(0);
  const [error, setError] = useState("");

  useEffect(() => {
    if (openingChapterSignal.value) {
      setTitle(openingChapterSignal.value.title);
      setDescription(openingChapterSignal.value.description);
      setId(openingChapterSignal.value.id);
      setOrder(openingChapterSignal.value.order);
    }
  }, [openingChapterSignal.value]);

  const handleClose = () => {
    modalOpen.value = false;
    clearForm();
  };

  const handleSave = async () => {
    const requiredFields = [title];

    if (requiredFields.some((field) => !field)) {
      setErrorMessage("Please fill in all required fields");
      return;
    }

    setIsSaving(true);

    try {
      if (id) {
        await ChapterService.updateChapter({
          id,
          title,
          description,
          order
        });
      } else {
        await ChapterService.createChapter({
          title,
          description,
          courseId: courseSignal.value.id,
          order
        });
      }
      const res = await getChapters({
        courseId: courseSignal.value.id,
        page: pageSignal.value
      });
      chaptersSignal.value = res;
    } catch (error) {
      console.error(error);
      if (error.response) {
        setErrorMessage(error.response.data.message);
        return;
      }
    }

    setIsSaving(false);
    clearForm();
    modalOpen.value = false;
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
    setId("");
    setOrder(0);
    openingChapterSignal.value = null;
    setError("");
  };

  const handleTitleChange = (e) => {
    setError("");
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setError("");
    setDescription(e.target.value);
  };

  const handleOrderChange = (e) => {
    setError("");
    // check if valid number and > 0
    if (isNaN(e.target.value)) return;
    const value = parseInt(e.target.value);
    if (value < 0) {
      setOrder(0);
      return;
    }
    if (value > 999999) {
      setOrder(999999);
      return;
    }
    setOrder(Number.parseInt(e.target.value));
  };

  const setErrorMessage = (message) => {
    setError(message);
    setIsSaving(false);
  };

  return (
    <React.Fragment>
      <Dialog open={modalOpen.value} onClose={handleClose}>
        <DialogTitle>{openingChapterSignal.value ? "Edit chapter" : "New chapter"}</DialogTitle>

        <DialogContent>
          <DialogContentText
            sx={{
              color: "red"
            }}>
            {error}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label="Title"
            type="text"
            fullWidth
            variant="standard"
            value={title}
            required
            onChange={handleTitleChange}
          />
          <TextField
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            multiline
            value={description}
            onChange={handleDescriptionChange}
          />
          <TextField
            margin="dense"
            id="course"
            label="Course"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={courseSignal.value.title}
            InputProps={{
              readOnly: true
            }}
          />
          <TextField
            margin="dense"
            id="order"
            label="Order"
            type="number"
            fullWidth
            variant="standard"
            value={order}
            onChange={handleOrderChange}
            // max value
            InputProps={{
              inputProps: {
                min: 1,
                max: 999999
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>{isSaving ? <CircularProgress /> : "Save"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EditorChapterListPage;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from "@mui/material";
import React from "react";

function PremiumOffer() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="w-full flex justify-center bg-[#F4F7FE] px-5">
        <div className="w-full h-[44vw] md:w-[68.125rem] md:h-[21.875rem]">
          <div className="w-full h-[44vw] flex relative md:w-[68.125rem] md:h-[21.875rem] rounded-[1.25rem]">
            <div>
              <img
                className="flex absolute rounded-[1.25rem] w-full h-[44vw] md:w-[68.125rem] md:h-[21.875rem] object-cover object-right md:object-center"
                src="../../../assets/premium-bg.png"
                alt="premium background"
              />
            </div>
            <div className="w-full font-[Inter] ml-6 my-4 md:ml-[4.44rem] md:my-[3.56rem] flex-col z-10">
              <div className="h-[26.5%] md:h-[37.5%] flex flex-col justify-center md:justify-start text-white text-[0.875rem] md:text-[2.125rem] font-bold md:font-medium leading-[2.625rem] tracking-[-0.0175rem] md:tracking-[-0.045rem]">
                <span className="w-[70%] md:w-[45%] flex md:block">
                  <div className="md:inline-flex min-w-[2.2rem] h-[2.75rem] mr-1 flex items-start md:items-center justify-center">
                    <img
                      src="../../../assets/premium-icon.svg"
                      alt="premium icon"
                      className="w-9 h-9 md:w-10 md:h-10"
                    />
                  </div>
                  Chào mừng bạn đến với nền tảng ôn thi tiếng Đức
                </span>
              </div>
              <p className="w-[50%] md:w-[40%] h-[38%] md:h-[35%] text-[#E3DAFF] text-base font-medium leading-7 tracking-[-0.02rem] mt-1 md:mt-[0.88rem] overflow-hidden text-ellipsis">
                Học tập hiệu quả để chinh phục các kỳ thi tiếng Đức dễ dàng!
              </p>
              <div className="flex gap-5 mt-2 md:mt-4 text-xs md:text-[0.875rem] font-medium leading-normal tracking-[-0.015rem] md:tracking-[-0.0175rem]">
                <button className="p-2 md:px-9 md:py-4 bg-white rounded-3xl md:rounded-2xl text-black">
                  Học ngay
                </button>
                <button
                  onClick={handleClickOpen}
                  className="p-2 md:px-9 md:py-3 bg-[#01B574] rounded-3xl md:rounded-2xl text-white">
                  Nâng cấp tài khoản
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Nâng cấp tài khoản Premium</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Vui lòng liên hệ ...</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PremiumOffer;

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useState } from "react";

dayjs.extend(utc);

export const CustomDateInputField = ({ id, name, value, onChange, required, disabled }) => {
  const [selectedDate, setSelectedDate] = useState(dayjs(value));

  const currentDate = dayjs();
  const handleDateChange = (date) => {
    if (date && date.isAfter(currentDate)) {
      onChange(currentDate.utc(true).format());
      setSelectedDate(currentDate);
    } else {
      onChange(date.utc(true).format());
      setSelectedDate(date);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        id={id}
        name={name}
        value={selectedDate}
        format="DD/MM/YYYY"
        placeholder="DD/MM/YYYY"
        required={required}
        disabled={disabled}
        maxDate={currentDate}
        onChange={handleDateChange}
        sx={{
          ".MuiInputBase-root.MuiOutlinedInput-root": {
            border: "none",
            borderRadius: 0,
            bgcolor: "transparent",
            color: "#2B3674",
            fontFamily: "Manrope",
            fontSize: "1rem",
            fontWeight: "bold",
            lineHeight: "1.5rem",
            opacity: 0.5,
            paddingLeft: 0,
            ":focus-within": {
              outline: "none",
              opacity: 1
            }
          },
          input: {
            padding: "16.5px 14px 16.5px 0"
          }
        }}
      />
    </LocalizationProvider>
  );
};

import { Menu } from "@headlessui/react";
import { Slide, Snackbar, Stack } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ExamService from "../../services/ExamService";
import { exam } from "../../signals/ExamSignal";
import Modal from "../Modal";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Transition(props) {
  return <Slide {...props} direction="down" />;
}

export function EditorTopBar() {
  const navigate = useNavigate();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [title, setTitle] = useState(exam.value.title);
  const [description, setDescription] = useState(exam.value.description);
  const [duration, setDuration] = useState(exam.value.duration ?? 0);
  const [error, setError] = useState(null);

  // Stackbar
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("success");
  const [detailStatus, setDetailStatus] = useState("");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    setTitle(exam.value.title);
    setDescription(exam.value.description);
    setDuration(exam.value.duration ?? 0);
  }, [exam.value]);

  const saveExam = async (e) => {
    setError(null);
    e.preventDefault();
    if (title === "" || Number.parseInt(duration) === 0) {
      setError("Title and duration are required");
      return;
    }

    // set exam title, description, duration and update order for each section, question
    exam.value = {
      ...exam.value,
      title,
      description,
      duration,
      order: exam.value.order ?? 1,
      sections: exam.value.sections.map((section, sectionIndex) => ({
        ...section,
        order: sectionIndex + 1,
        questions: section.questions.map((question, questionIndex) => ({
          ...question,
          order: questionIndex + 1
        }))
      }))
    };

    try {
      await ExamService.createFullExam({
        exam: exam.value
      });
      setOpen(true);
      setStatus("success");
      setDetailStatus("Lưu thành công. Chuyển hướng về danh sách bài thi sau 3 giây");
      for (let i = 0; i < 3; i++) {
        setTimeout(() => {
          setDetailStatus(`Lưu thành công. Chuyển hướng về danh sách bài thi sau ${3 - i} giây`);
        }, 1000 * i);
      }
      setTimeout(() => {
        navigate(-1, { replace: true });
      }, 3000);
    } catch (error) {
      console.error(error);
      setOpen(true);
      setStatus("error");
      setDetailStatus("Lưu thất bại");
    }

    setIsSaveModalOpen(false);
  };

  return (
    <>
      <div className="w-full h-14 bg-white px-16 flex items-center justify-between">
        <div>
          <p className="text-[#1BAE3B] capitalize text-base font-normal px-2">
            {title ?? "New exam"}
          </p>
          <div className=" w-full h-0.5 bg-[#1BAE3B] rounded-full"></div>
        </div>
        <div className="flex items-center gap-4">
          {/* <button className="flex items-center gap-2 text-[#5F5B5B] text-base font-normal">
              <i>
                <img src="/images/eye.svg" alt="eye" />
              </i>
              <p>Preview</p>
            </button> */}
          <button
            onClick={() => {
              setIsSaveModalOpen(true);
            }}
            className="w-max h-max bg-[#2639ED] rounded-md text-white text-sm font-medium leading-3 flex items-center justify-start px-5 gap-4 py-3">
            Save exam
          </button>
          <Menu as="div" className="z-10">
            <div>
              <Menu.Button className="inline-flex justify-center w-6 h-6 items-center">
                <i>
                  <img className="rotate-90" src="/assets/three-dots.svg" alt="three dot" />
                </i>
              </Menu.Button>
            </div>
            <Transition
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95">
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className={`${
                          active ? "bg-[#2639ED] text-white" : "text-red-500"
                        } group flex w-full items-center rounded-md px-2 py-2 text-sm font-medium`}
                        onClick={() => navigate(-1, { replace: true })}>
                        Discard
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <Modal title="Save exam" isOpen={isSaveModalOpen} setIsOpen={setIsSaveModalOpen}>
        <form className="mt-4 flex flex-col gap-3">
          {error && <p className="text-red-500 text-sm font-normal">{error}</p>}
          <input
            type="text"
            placeholder="Exam title"
            className="w-full h-10 border border-[#E5E5E5] rounded-md px-4 py-2 focus:outline-none"
            value={title}
            required
            onChange={(e) => {
              setError(null);
              setTitle(e.target.value);
            }}
          />
          <input
            type="text"
            placeholder="Exam description"
            className="w-full h-10 border border-[#E5E5E5] rounded-md px-4 py-2 focus:outline-none"
            value={description}
            onChange={(e) => {
              setError(null);
              setDescription(e.target.value);
            }}
          />
          <input
            type="number"
            placeholder="Exam duration"
            className="w-full h-10 border border-[#E5E5E5] rounded-md px-4 py-2 focus:outline-none"
            value={duration}
            min={1}
            max={9999}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }

              const value = Number.parseInt(e.target.value);
              setError(null);
              if (value < 1) {
                setDuration(1);
                return;
              }
              if (value > 9999) {
                setDuration(9999);
                return;
              }
              setDuration(value);
            }}
          />

          <button
            type="submit"
            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={saveExam}>
            Save
          </button>
        </form>
      </Modal>
      <Stack>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          TransitionComponent={Transition}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={status} sx={{ width: "100%" }}>
            {detailStatus}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
}

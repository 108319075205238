import { Avatar } from "@mui/material";
import Util from "../../utils/Util";

const CustomStaticAvatar = ({ firstName, lastName, avatar, className }) => {
  if (avatar) {
    return <img src={avatar} alt="user avatar" className={className} />;
  }
  return <Avatar className={className} {...Util.stringAvatar(`${lastName} ${firstName}`)} />;
};

export default CustomStaticAvatar;

const CourseSection = ({ course }) => {
  return (
    <div className="w-full px-4 md:px-12 text-center">
      <h2 className="text-base md:text-[1.875rem] text-[#4412ED] font-bold leading-normal md:mt-14 mb-[0.7rem]">
        {course?.title}
      </h2>
      <p className="text-[#A3AED0] text-center text-[0.875rem] md:text-[0.9375rem] font-medium leading-[1.375rem] md:leading-7 tracking-[-0.0175rem] md:tracking-[-0.01875rem]">
        {course?.description}
      </p>
    </div>
  );
};

export default CourseSection;
